import { TextField } from "@mui/material";

export const SegurosClientesExistentesColombia = ({
  nombreCliente,
  setNombreCliente,
  nombreEmpresa,
  setNombreEmpresa,
}) => {
  return (
    <>
      <TextField
        label="Nombre cliente"
        variant="filled"
        sx={{
          color: "white",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
        fullWidth
        margin="normal"
        value={nombreCliente}
        onChange={(e) => setNombreCliente(e.target.value)}
        required
      />
      <TextField
        label="Nombre empresa de seguros"
        variant="filled"
        sx={{
          color: "white",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
        fullWidth
        margin="normal"
        value={nombreEmpresa}
        onChange={(e) => setNombreEmpresa(e.target.value)}
        required
      />
    </>
  );
};
