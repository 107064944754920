import { TextField } from "@mui/material";

export const PoliticalDemoColombia = ({
  nombreEncuestado,
  setNombreEncuestado,
  temaEspecifico,
  setTemaEspecifico,
  areaEspecifica,
  setAreaEspecifica,
}) => {
  return (
    <>
      <TextField
        label="Nombre"
        variant="filled"
        sx={{
          color: "white",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
        fullWidth
        margin="normal"
        value={nombreEncuestado}
        onChange={(e) => setNombreEncuestado(e.target.value)}
        required
      />
      <TextField
        label="Tema específico"
        variant="filled"
        sx={{
          color: "white",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
        fullWidth
        margin="normal"
        value={temaEspecifico}
        onChange={(e) => setTemaEspecifico(e.target.value)}
        required
      />
      <TextField
        label="Area específica"
        variant="filled"
        sx={{
          color: "white",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
        fullWidth
        margin="normal"
        value={areaEspecifica}
        onChange={(e) => setAreaEspecifica(e.target.value)}
        required
      />
    </>
  );
};
