import { TextField } from "@mui/material";

export const BancosDemoColombia = ({
  nombreCliente,
  setNombreCliente,
  nombreBanco,
  setNombreBanco,
  // cantidad,
  // setCantidad,
}) => {
  return (
    <>
      <TextField
        label="Nombre banco"
        variant="filled"
        sx={{
          color: "white",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
        fullWidth
        margin="normal"
        value={nombreBanco}
        onChange={(e) => setNombreBanco(e.target.value)}
        required
      />
      <TextField
        label="Nombre cliente"
        variant="filled"
        sx={{
          color: "white",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
        fullWidth
        margin="normal"
        value={nombreCliente}
        onChange={(e) => setNombreCliente(e.target.value)}
        required
      />
      {/* <TextField
        label="Cantidad de dinero para bono de traer nomina"
        variant="filled"
        sx={{
          color: "white",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
        fullWidth
        margin="normal"
        value={cantidad}
        onChange={(e) => setCantidad(e.target.value)}
        required
      /> */}
    </>
  );
};
