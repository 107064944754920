import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  useMediaQuery,
  Box,
  Divider,
} from "@mui/material";
import logo from "../assets/logo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../App.css";
import { Call, Schedule } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import axios from "axios";
import { PoliticalDemoColombia } from "./colombia/political-demo";
import { DealershipDemoColombia } from "./colombia/dealership-demo";
import { BancosDemoColombia } from "./colombia/bancos-demo";
import { SegurosClientesExistentesColombia } from "./colombia/seguros-clientes-existentes";
import { SegurosClientesNuevosColombia } from "./colombia/seguros-prospeccion-clientes";

function Colombia() {
  const [nombreEncuestado, setNombreEncuestado] = useState("");
  const [temaEspecifico, setTemaEspecifico] = useState("");
  const [areaEspecifica, setAreaEspecifica] = useState("");
  const [nombreConcesionario, setNombreConcesionario] = useState("");
  const [nombreProspecto, setNombreProspecto] = useState("");

  /**
   * Bancos DEMO
   */
  const [nombreCliente, setNombreCliente] = useState("");
  const [nombreBanco, setNombreBanco] = useState("");
  const [cantidad, setCantidad] = useState("");

  /**
   * Seguros Clientes Existentes DEMO
   */
  const [nombreEmpresa, setNombreEmpresa] = useState("");

  // const [dealershipName, setDealershipName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("co");
  const [callType, setCallType] = useState("political"); // State for phone call type
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const handleSubmit = async (event) => {
    // let newDealershipName = dealershipName
    //   .toLowerCase()
    //   .replace("automotriz", " ");

    event.preventDefault();

    const fromNumber = "576045012169";
    const baseUrl =
      "https://initiateretellcall-655075094783.us-central1.run.app";

    let url = "";

    console.log(`Call type is ${callType}`);

    switch (callType) {
      case "political":
        url = `${baseUrl}?nombre_encuestado=${nombreEncuestado}&tema_específico=${temaEspecifico}&área_específica=${areaEspecifica}&to_number=${phoneNumber}&from_number=${fromNumber}&agent_id=agent_b5c9e10f3ad7b3936a4a7f0cca`;
        break;
      case "bancos":
        url = `${baseUrl}?nombre_cliente=${nombreCliente}&nombre_banco=${nombreBanco}&cantidad=1000000&to_number=${phoneNumber}&from_number=${fromNumber}&agent_id=agent_cc42a2754a5c82b835869ccda3`;
        break;
      case "seguros_clientes_existentes":
        url = `${baseUrl}?nombre_cliente=${nombreCliente}&nombre_empresa=${nombreEmpresa}&to_number=${phoneNumber}&from_number=${fromNumber}&agent_id=agent_6d87d840be5b1a3857612d8a07`;
        break;
      case "seguros_prospeccion_clientes_nuovos":
        url = `${baseUrl}?nombre=${nombreCliente}&nombre_empresa_seguros=${nombreEmpresa}&to_number=${phoneNumber}&from_number=${fromNumber}&agent_id=agent_a535c4d1e11f57f50071db37f9`;
        break;
      case "dealership":
        url = `${baseUrl}?nombre=${nombreProspecto}&nombre_concessionario=${nombreConcesionario}&to_number=${phoneNumber}&from_number=${fromNumber}&agent_id=agent_27806fa00809a1ac2591425c6d`;
        break;
      default:
        break;
    }

    console.log(`Starting call with type: ${callType} and URL ${url}`);

    await axios
      .request(url, {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => console.log(res));
  };

  return (
    <div className="full-page-background">
      <Grid container spacing={2} direction={isSmallScreen ? "column" : "row"}>
        <Grid item md={12}>
          <Container className="App" maxWidth="sm">
            <img src={logo} className="App-logo" alt="logo" />
            <h1>AutoLlamadas Inteligentes</h1>
            <h3>
              Revoluciona Tu Empresa con Nuestro Sistema de Llamadas Automáticas
            </h3>
            <form onSubmit={handleSubmit}>
              <Select
                value={callType}
                onChange={(e) => setCallType(e.target.value)}
                required
                sx={{
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
              >
                <MenuItem value="political">Encuesta Politica</MenuItem>
                <MenuItem value="dealership">Concesionario</MenuItem>
                <MenuItem value="seguros_clientes_existentes">
                  Seguros (Clientes Existentes)
                </MenuItem>
                <MenuItem value="seguros_prospeccion_clientes_nuovos">
                  Seguros (Prospección Clientes Nuevos)
                </MenuItem>
                <MenuItem value="bancos">Bancos (Clientes Existentes)</MenuItem>
              </Select>
              {callType === "seguros_clientes_existentes" && (
                <SegurosClientesExistentesColombia
                  nombreCliente={nombreCliente}
                  setNombreCliente={setNombreCliente}
                  nombreEmpresa={nombreEmpresa}
                  setNombreEmpresa={setNombreEmpresa}
                />
              )}
              {callType === "seguros_prospeccion_clientes_nuovos" && (
                <>
                  <SegurosClientesNuevosColombia
                    nombreCliente={nombreCliente}
                    setNombreCliente={setNombreCliente}
                    nombreEmpresa={nombreEmpresa}
                    setNombreEmpresa={setNombreEmpresa}
                  />
                </>
              )}
              {callType === "bancos" && (
                <BancosDemoColombia
                  nombreCliente={nombreCliente}
                  setNombreCliente={setNombreCliente}
                  nombreBanco={nombreBanco}
                  setNombreBanco={setNombreBanco}
                  cantidad={cantidad}
                  setCantidad={setCantidad}
                />
              )}
              {callType === "political" && (
                <PoliticalDemoColombia
                  nombreEncuestado={nombreEncuestado}
                  setNombreEncuestado={setNombreEncuestado}
                  temaEspecifico={temaEspecifico}
                  setTemaEspecifico={setTemaEspecifico}
                  areaEspecifica={areaEspecifica}
                  setAreaEspecifica={setAreaEspecifica}
                />
              )}
              {callType === "dealership" && (
                <DealershipDemoColombia
                  nombreConcesionario={nombreConcesionario}
                  setNombreConcesionario={setNombreConcesionario}
                  nombreProspecto={nombreProspecto}
                  setNombreProspecto={setNombreProspecto}
                />
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth margin="normal">
                    <Select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "4px",
                      }}
                      required
                    >
                      {/* <MenuItem value="mx">🇲🇽 (+52)</MenuItem> */}
                      {/* <MenuItem value="es">🇪🇸 (+34)</MenuItem> */}
                      <MenuItem value="co">🇨🇴 (+57)</MenuItem>
                      {/* <MenuItem value="us">🇺🇸 (+1)</MenuItem> */}
                      <MenuItem value="ca">🇨🇦 (+1)</MenuItem>
                      <MenuItem value="gb">🇬🇧 (+44)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Box width={"100%"}>
                    <br />
                    <PhoneInput
                      country={country}
                      value={phoneNumber}
                      disableDropdown={true}
                      disableCountryGuess={true}
                      onChange={setPhoneNumber}
                      enableLongNumbers={true}
                      inputStyle={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                        height: "56px",
                        borderRadius: "4px",
                        border: "1px solid #ced4da",
                      }}
                      required
                    />
                  </Box>
                </Grid>
              </Grid>
              <br />
              <h3>
                Al presionar, recibirá una llamada de demostración sin costo de
                este número:
                <br />
                <b>+57 6045012169</b>
              </h3>
              <br />
              <Button
                className="callBtn"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                <h2
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Presione aquí - demostración gratuita
                </h2>
                <div style={{ width: "20px" }} />
                <Call className="callIcon" />
              </Button>
            </form>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}

export default Colombia;
