import { TextField } from "@mui/material";

export const DealershipDemoColombia = ({
  nombreEncuestado,
  setNombreEncuestado,
  nombreConcesionario,
  setNombreConcesionario,
}) => {
  return (
    <>
      <TextField
        label="Nombre prospecto"
        variant="filled"
        sx={{
          color: "white",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
        fullWidth
        margin="normal"
        value={nombreEncuestado}
        onChange={(e) => setNombreEncuestado(e.target.value)}
        required
      />
      <TextField
        label="Nombre del concesionario"
        variant="filled"
        sx={{
          color: "white",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
        fullWidth
        margin="normal"
        value={nombreConcesionario}
        onChange={(e) => setNombreConcesionario(e.target.value)}
        required
      />
    </>
  );
};
