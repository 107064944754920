import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import logo from "../../assets/logo.png";
import { Call } from "@mui/icons-material";

export const InsuranceDemo = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [country, setCountry] = useState("us");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const fromNumber = "15489185087";
    const baseUrl =
      "https://initiateretellcall-655075094783.us-central1.run.app";

    let url = "";

    url = `${baseUrl}?user_first_name=${userFirstName}&agency_name=${encodeURIComponent(
      agencyName
    )}&to_number=${phoneNumber}&from_number=${fromNumber}&agent_id=agent_83113a836151a53f17ffd7c944`;

    console.log(`Starting call with URL ${url}`);

    await axios
      .request(url, {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => console.log(res));
  };

  return (
    <div className="full-page-background">
      <Grid item xs={12} md={7}>
        <Container className="App" maxWidth="sm">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Insurance Lead Magnet DEMO</h1>
          <h3>
            Testing website for JasperVOCAL's Insurance Agent Autodialer Lead
            Magnet
          </h3>
          <form onSubmit={handleSubmit}>
            <TextField
              label="First name"
              variant="filled"
              sx={{
                color: "white",
                backgroundColor: "white",
                borderRadius: "4px",
              }}
              fullWidth
              margin="normal"
              value={userFirstName}
              onChange={(e) => setUserFirstName(e.target.value)}
              required
            />
            <TextField
              label="Agency name"
              variant="filled"
              sx={{
                color: "white",
                backgroundColor: "white",
                borderRadius: "4px",
              }}
              fullWidth
              margin="normal"
              value={agencyName}
              onChange={(e) => setAgencyName(e.target.value)}
              required
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "4px",
                    }}
                    required
                  >
                    <MenuItem value="us">🇺🇸 (+1)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box width={"100%"}>
                  <br />
                  <PhoneInput
                    country={country}
                    value={phoneNumber}
                    disableDropdown={true}
                    disableCountryGuess={true}
                    onChange={setPhoneNumber}
                    enableLongNumbers={true}
                    inputStyle={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      height: "56px",
                      borderRadius: "4px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </Box>
              </Grid>
            </Grid>
            <br />
            <h3>
              After clicking to receive the DEMO call, you will receive a call
              from the following phone number: <b>+1 (548) 918-5087</b>
            </h3>
            <br />
            <Button
              className="callBtn"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              <h2
                style={{
                  fontSize: "16px",
                }}
              >
                Click here to receive the DEMO call
              </h2>
              <div style={{ width: "20px" }} />
              <Call className="callIcon" />
            </Button>
          </form>
        </Container>
      </Grid>
    </div>
  );
};
